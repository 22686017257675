import React from 'react'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import AppBody from '../AppBody'

export default function PoolFinder() {
  return (
    <AppBody>
    <SwapPoolTabs active={'fiat'} />
            Click Buy BNB to buy with Fiat and swap to PIT
      <iframe
        width="400"
        height="630"
        src="https://www.flooz.trade/embedded/0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50/?backgroundColor=transparent&refId=ZMn6Sp&chainId=56&fromToken=bnb"
        title="Flooz Trade"
        frameBorder="0"
        allow="clipboard-read; clipboard-write;"
      >
        {' '}
      </iframe>
    </AppBody>
  )
}
